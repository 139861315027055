import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApiStore } from './api';

export const useAuthStore = defineStore(
    'auth',
    () => {
        const token = ref(null);
        const user = ref(null);
        const localUserSettings = ref({
            darkMode: 'auto',
        });
        const toast = ref({});

        const apiStore = useApiStore();
        const api = apiStore.api;

        if (token.value) {
            apiStore.setToken(token.value);
        }

        const checkLogin = () => {
            if (token.value && user.value) {
                return api
                    .get('/profile')
                    .json((json) => {
                        user.value = json;
                    })
                    .catch((error) => {
                        user.value = null;
                        token.value = null;

                        return Promise.reject(error);
                    });
            } else {
                user.value = null;
                token.value = null;

                return Promise.reject(new Error('Token not found'));
            }
        };

        const setUser = (newUser) => {
            user.value = newUser;
        };

        const checkToken = async (newToken) => {
            if (newToken) {
                return api
                    .get('/sso/' + newToken)
                    .json((json) => {
                        user.value = json.user;
                        token.value = json.token;
                        apiStore.setToken(json.token);
                    })
                    .catch((error) => {
                        return Promise.reject(error.json);
                    });
            } else {
                return Promise.reject(new Error('Token not found'));
            }
        };

        const changeLocalSetting = (setting, value) => {
            localUserSettings.value[setting] = value;
        };

        const logout = () => {
            token.value = null;
            user.value = null;
        };

        const addToast = (toasty) => {
            toasty.timestamp = Math.floor(Date.now() / 1000);
            toast.value = toasty;
        };

        const checkPermission = (permission) => {
            if (user.value.permissions.includes('*')) {
                return true;
            }

            return user.value.permissions.includes(permission);
        };

        return {
            token,
            user,
            localUserSettings,
            toast,
            checkLogin,
            setUser,
            checkToken,
            changeLocalSetting,
            logout,
            addToast,
            checkPermission,
        };
    },
    {
        persist: true,
    }
);
