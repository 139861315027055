import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export function initializeBugsnag(app) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        enabledReleaseStages: ['production'],
        releaseStage: import.meta.env.MODE,
    });

    const bugsnagVue = Bugsnag.getPlugin('vue');

    app.use(bugsnagVue);
}
